import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Trend from '../components/trends.js';
import Find from '../components/NewFind.js'; 
import NoseContent from '../sections/nose/nosecontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Gallery from "../sections/nose/nosegallery.js";
import Glance from "../components/glance.js";
import Testimonial from "../sections/index/indexreviews.js";
import Liquid from "../sections/nose/nosevs.js";
import Nosefaq from "../sections/nose/nosefaq.js"
import Steps from '../sections/nose/nosesteps.js';
import NoseBlog from "../sections/nose/noseblog.js";
import Video from "../sections/nose/nosevideo.js";
import Award from "../components/NewToxane.js";
import Saveface from '../components/save.js';
import HeroComponent from "../components/HeroComponent";
import imgVideo from "../assets/image/jpeg/dermamina-nose-video.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PriceBanner from '../components/noseprice.js';

const NonSurgicalNoseJobPage = () => ( 
  <>
 <Head title="Non Surgical Nose Job, Rhinoplasty in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

]
}}
</JsonLd>


<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is Non Surgical Nose Job?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Non-Surgical Nose Job (non-surgical rhinoplasty) - also known as liquid nose job, is an advanced non invasive procedure that can provide a quick fix in the reshaping and modifying of the nose. The procedure involves injecting dermal fillers strategically to the nose to achieve a better defined, straightened and lifted nose, and providing more harmony and balance to the shape. The procedure itself will not make the nose bigger or smaller, however a straighter and more defined nose can give the illusion that the nose is smaller and in harmony with the other features of the face. Additionally, it can enhance the frontal view of the face by contouring, improving asymmetry or deviation of the nose."
            }
          },
          {
            "@type": "Question",
            "name": "How long does the Non-Surgical Rhinoplasty procedure take?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Non surgical rhinoplasty takes no longer than 15 minutes; patients will see immediate results, with no recovery time needed, and you can get back to your daily activities or work the very same day."
            }
          },
          {
            "@type": "Question",
            "name": "Does non surgical rhinoplasty hurt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We will apply numbing cream (optional) to the nose before the treatment begins to make your experience as smooth and painless as possible. Only when the numbing cream has taken its full affect do we begin the treatment. Based on our previous patients, many do not feel pain due to the effect of the anaesthetic, others feel mild pain with minimal discomfort."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Non Surgical Nose Job safe?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Non-Surgical Nose Job is done by injecting hyaluronic acid gel into the soft tissue in the nose to shape, contour and straighten the nose. Hyaluronic acid based dermal fillers are derived naturally and not animal based. It is known as one of nature’s most versatile and is made with extensive research in the laboratory. Over time the fillers get dissolved naturally in the body as the body is able to break the filler down and results of the treatment gradually fade. The filler breaks down and is re-absorbed by the body, just like the natural occurring hyaluronic acid in the skin. Hyaluronic acid has gained a reputation for being safe, effective and a good alternative to surgery."
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "600"
      }}
    </JsonLd>



    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Non Surgical Nose Job",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "Non Surgical Nose Job Treatment London",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.com/non-surgical-nose-job",
          "priceCurrency": "GBP",
          "price": "450",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "Non Surgical Nose Job - Feel great with a smoother nose",
        "description": "Video explaining what is Non Surgical Nose Job?",
        "thumbnailUrl": "http://dermamina.com/static/dermamina-nose-video-e193fa9f2ea4df0a8f1abcc817fa8774.jpg",
        "uploadDate": "2024-08-01T17:00:00+01:00",
        "embedUrl": "https://youtu.be/BmEpcgPOYak?si=8nJwt_zJG2hQIht0",
        "contentUrl": "https://www.youtube.com/watch?v=BmEpcgPOYak"
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before and After Non Surgical Nose Job",
            "contentUrl": "https://www.dermamina.com/static/liquid7-b31718abd5317f1ae2ccaf6fb7086932.jpg",
            "description": "Image showing hair condition before and after Non Surgical Nose Job treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "Before and After Non Surgical Nose Job",
            "contentUrl": "https://www.dermamina.com/static/liquid-rhinoplasty4-a43b7be03f2c9a16736911262c0a7fe8.jpg",
            "description": "Image showing hair condition before and after Non Surgical Nose Job treatment."
          }
        ]
      }}
    </JsonLd>


      <PageWrapper headerDark footerDark>
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Non Surgical </span> <span style="color: #00aec7;">Rhinoplasty</span>'
    secondText="Feel great with a smoother nose"
    videoUrl="https://www.youtube.com/watch?v=BmEpcgPOYak" // Specify your video URL here
    videoAlt="Non Surgical Nose Job Video"
    imgVideo={imgVideo} // Specify your image URL here
  />
      

      <Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Non Surgical Nose Job"
  resultstext="9-18 months"
  resultsalt="Non Surgical Nose Job results"
  costdata="£450"
  costalt="Non Surgical Nose Job Cost"
  timedata="10 Minutes"
  timealt="Non Surgical Nose Job Duration Time"
  workdata="Immediately"
  workalt="Non Surgical Nose Job downtime work"
  paindata="None / Mild"
  painalt="Non Surgical Nose Job Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>

    
      <Gallery />
      <PriceBanner first />
      <NoseContent />

      <Testimonial />
      <Award />
      <Video />
      <Liquid />
      <Steps />
      <Nosefaq />
      <NoseBlog />
      <Find />
      <Saveface />
      <Trend />
 
     </PageWrapper>
 
  </>
)
export default NonSurgicalNoseJobPage
